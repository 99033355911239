.MuiBox-root {
	display        : flex;
	align-items    : center;
	justify-content: center;
	width          : 100%;
	height         : 100%;


	.email-customization-modal {
		width   : 70%;
		height  : 90%;
		padding : 32px 24px 32px 24px;
		overflow: auto;
		position: relative;
		background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
			rgba(0, 0, 0, 0.0001);
		box-shadow   : 0px 4px 24px rgba(0, 0, 0, 0.06);
		border-radius: 6px;

		.email-customization-modal-header {
			width          : 100%;
			display        : flex;
			align-items    : center;
			justify-content: space-between;
			border-bottom  : 1px solid #DEDAF2;
			margin-bottom  : 32px;
		}

		.email-customization-modal-container {
			width         : 100%;
			display       : flex;
			flex-direction: row;
			gap           : 24px;

			.email-customization-modal-container-left {
				width: 50%;

				.ql-toolbar.ql-snow {
					flex-wrap: wrap;

					.ql-formats {
						display: flex;
					}
				}

				.custom-options {
					position        : absolute;
					left            : 220px;
					background-color: white;
					z-index         : 1000;
					width           : 150px;

					.option {
						padding: 5px;
					}
				}

				#toolbar {
					display: flex;
				}
			}

			.email-customization-modal-container-right {
				width         : 50%;
				display       : flex;
				flex-direction: column;
				gap           : 10px;

				.template-container {
					border-radius: 6px;
					border       : 1px solid #DEDAF2;

					.default-text button {
						width: 195px;
					}

					.ql-size-large {
						font-size: 1.5em;
					}
				}
			}
		}
	}

	.modal-container {
		// width     : calc(100% - 48px);
		// background: white;
		// margin-top: 158px;
		// padding : 24px;
		width   : 604px;
		position: relative;
		background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
			rgba(0, 0, 0, 0.0001);
		box-shadow   : 0px 4px 24px rgba(0, 0, 0, 0.06);
		border-radius: 24px;
		gap          : 24px;

		.cross-icon {
			height         : 37px;
			width          : 37px;
			display        : flex;
			align-items    : center;
			justify-content: center;
			right          : -17px;
			position       : absolute;
			top            : -17px;
			background     : rgba(255, 255, 255, 1);
			border-radius  : 6px;
			cursor         : pointer;
			box-shadow     : 0px 3px 8px rgba(167, 174, 181, 0.4);
		}
	}

	.modal-action-btn {
		width                     : 100%;
		display                   : flex;
		padding                   : 24px;
		border-top                : 1px solid #DEDAF2;
		background                : rgba(255, 255, 255, 0.3);
		margin-top                : 16px;
		justify-content           : space-between;
		border-bottom-right-radius: 24px;
		border-bottom-left-radius : 24px;
	}

	.modal-container-w-quill {
		height    : 80vh;
		max-height: 735px;
		width     : 80vw;
		max-width : 1200px;
		padding   : 0px;
		position  : relative;
		overflow  : scroll;
		background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
			rgba(0, 0, 0, 0.0001);
		// box-shadow   : 0px 4px 24px rgba(0, 0, 0, 0.06);
		border-radius: 24px;

		.modal-inner-container-w-quill {
			width         : 100%;
			height        : calc(100% - 112px);
			display       : flex;
			flex-direction: column;
			align-items   : flex-start;
			padding       : 32px 32px 0px;
		}

		.jd-quill-container {
			width                 : 100%;
			height                : calc(100% - 90px);
			display               : flex;
			align-items           : flex-start;
			justify-content       : center;
			margin-top            : 24px;
			// border-radius      : 24px;
			// background-color: $dark-20 !important;
			border                : none;

			.jd-quill-inner-container {
				margin-top   : 0px;
				height       : 100%;
				background   : #F6F5F8 !important;
				border-radius: 24px;
				padding      : 18px;

				.ql-toolbar {
					min-height   : 40px;
					background   : #ffffff !important;
					border-radius: 24px;

				}

				.ql-container {
					height: calc(100% - 40px) !important;

					.ql-editor {
						border-radius   : 0px 0px 6px 6px;
						background      : #F6F5F8 !important;
						// border-radius: 24px;
						// padding      : 8px 24px;
						margin-top      : 12px;
					}
				}
			}
		}
	}

	.hubspot-meeting-modal-container {
		width        : 80vw;
		max-width    : 900px;
		padding      : 12px 0px;
		position     : relative;
		background   : linear-gradient(0deg, #FFFFFF, #FFFFFF), rgba(0, 0, 0, 0.0001);
		box-shadow   : 0px 4px 24px rgba(0, 0, 0, 0.06);
		border-radius: 6px;

		.cross-icon {
			height         : 37px;
			width          : 37px;
			display        : flex;
			align-items    : center;
			justify-content: center;
			right          : -17px;
			position       : absolute;
			top            : -17px;
			background     : rgba(255, 255, 255, 1);
			border-radius  : 6px;
			cursor         : pointer;
			box-shadow     : 0px 3px 8px rgba(167, 174, 181, 0.4);
		}

		.hubspot-meeting-modal-inner-container {
			width          : 100%;
			height         : 90vh;
			max-height     : 690px;
			overflow       : scroll;
			display        : flex;
			flex-direction : column;
			align-items    : center;
			justify-content: center;
			gap            : 12px;
		}
	}

	.modal-container-without-btn {
		// width     : calc(100% - 48px);
		// background: white;
		margin  : auto;
		padding : 32px 16px;
		width   : 1000px;
		position: relative;
		background: linear-gradient(0deg, #FFFFFF, #FFFFFF),
			rgba(0, 0, 0, 0.0001);
		box-shadow   : 0px 4px 24px rgba(0, 0, 0, 0.06);
		border-radius: 6px;
		height       : 85vh;

		.cross-icon {
			height         : 37px;
			width          : 37px;
			display        : flex;
			align-items    : center;
			justify-content: center;
			right          : -17px;
			position       : absolute;
			top            : -17px;
			background     : rgba(255, 255, 255, 1);
			border-radius  : 6px;
			cursor         : pointer;
			box-shadow     : 0px 3px 8px rgba(167, 174, 181, 0.4);
		}
	}
}

.candidate-modal-container {
	padding   : 0px !important;
	width     : 100% !important;
	max-width : 736px !important;
	align-self: center !important;
	margin-top: 0px !important;
}

.candidate-side-assessment-result-modal-container {
	display        : flex;
	align-items    : center;
	justify-content: flex-end;

	.candidate-side-assessment-result-modal {
		width   : 520px;
		height  : 100vh;
		padding : 32px 24px;
		position: relative;
		background: linear-gradient(0deg, #DEDAF2, #DEDAF2),
			rgba(0, 0, 0, 0.0001);
		box-shadow   : 0px 4px 24px rgba(0, 0, 0, 0.06);
		border-radius: 6px 0px 0px 6px;
		overflow     : scroll;
	}

	.candidate-side-assessment-result-modal::-webkit-scrollbar {
		display: none;
	}
}


.contact-support-for-search-container {
	/* Auto layout */
	display       : flex;
	flex-direction: column;
	align-items   : center;
	padding       : 0px;
	gap           : 24px;

	width    : 604px;
	// height: 428px;

	/* Primary/White */
	background: #FFFFFF;

	/* Primary/Light */
	border          : 0.6px solid #DEDAF2;
	border-radius   : 6px;
	position        : relative;
	// overflow     : scroll;

	.contact-support-for-search-inner-container {
		width          : 100%;
		display        : flex;
		flex-direction : row;
		align-items    : flex-start;
		justify-content: space-between;
	}

	.cross-icon {
		position: absolute;
		right   : 32px;
		top     : 32px;
		cursor  : pointer;
	}

	textarea {
		width     : 100%;
		height    : 168px;
		max-height: 168px;
		min-height: 168px;
		padding   : 12px;

		background   : #FFFFFF;
		border       : 0.6px solid #6F2DBD;
		border-radius: 6px;
		outline      : none;
	}

	span {
		text-align: start;
	}

	.secondary-btn-v2 {
		background        : #ffffff;
		// border         : 1px solid #392D69;
		display           : flex;
		align-items       : center;
		justify-content   : center;

		svg {
			path {
				stroke: #392D69;
			}
		}
	}

	.question-select-responsive {
		max-width    : 210px;
		margin       : 0px !important;
		background   : #6F2DBD !important;
		height       : 32px !important;
		border-radius: 3px !important;


		.select-container {

			background   : #6F2DBD !important;
			border-radius: 3px !important;
			border-color : #6F2DBD;
			height       : 32px !important;

			input {
				height          : 32px !important;
				background      : #6F2DBD !important;
				border-radius   : 3px !important;
				// border-radius: 3px;
				color           : white;
				font-weight     : 500;
			}

			svg {
				height: 20px;
				width : 20px;
			}
		}
	}
}

.copy-input-container {
	width      : 100%;
	display    : flex;
	align-items: center;

	.copy-input {
		max-width    : 100%;
		width        : 100%;
		height       : 44px;
		display      : flex;
		align-items  : center;
		padding      : 0px 24px 0px 6px;
		background   : #F6F5F8;
		border-radius: 24px;
		gap          : 8px;

		.on-boarding-input {
			width: 100%;
		}
	}
}



@media only screen and (min-width: 768px) and (max-width: 1279px) {
	.MuiBox-root {
		.email-customization-modal {
			.email-customization-modal-container {
				flex-direction: column;
				gap           : 10px;

				.email-customization-modal-container-left {
					width: 100%;

					.ql-container.ql-snow {
						min-height: 300px;
					}
				}

				.email-customization-modal-container-right {
					width: 100%;

					.template-container {
						.action-button {
							padding: 16px 100px;
						}
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.MuiBox-root {
		.email-customization-modal {
			width: 90%;

			.email-customization-modal-header {
				min-width: calc(410px - 24px);
			}

			.email-customization-modal-container {
				min-width     : 410px;
				flex-direction: column;
				gap           : 10px;

				.email-customization-modal-container-left {
					min-width: calc(100% - 24px);

					.ql-toolbar.ql-snow {
						padding: 5px;
					}

					.ql-container.ql-snow {
						min-height: 300px;
					}

					.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
						right: -11px;
					}

					.ql-snow .ql-picker-label {
						padding-left: 0px;
					}
				}

				.email-customization-modal-container-right {
					min-width: calc(100% - 24px);

					.template-container {
						.container {
							padding: 12px;
						}

						margin: 0px !important;

						.social-group-wrapper {
							width    : 100%;
							flex-wrap: wrap;
							gap      : 5px;

							a {
								margin: 0 !important;
							}
						}

						.action-button {
							padding: 16px 50px;
						}
					}
				}
			}
		}
	}

	.MuiBox-root {
		padding    : 106px 0px;
		// overflow: scroll;

		.modal-container {
			width           : calc(100% - 48px);
			max-width       : 500px;
			position        : relative;
			background      : white;
			// padding      : 32px 16px;
			// margin-top: 106px;

		}

		.modal-container-w-quill {
			.jd-quill-container {
				height: calc(100% - 142px);

				.jd-quill-inner-container {
					.ql-container {
						height: calc(100% - 90px) !important;
					}
				}
			}
		}
	}

	.candidate-side-assessment-result-modal-container {
		.candidate-side-assessment-result-modal {
			width        : 100%;
			height       : calc(100vh - 100px);
			border-radius: 0px 0px 0px 0px;
			margin-top   : 100px;
		}
	}

	.contact-support-for-search-container {
		width : 100%;
		margin: 24px;

		.contact-support-for-search-inner-container {
			flex-direction: column;
			gap           : 12px;
		}
	}
}

//---------------------------------------------------------------------------------------------------------------------------
.role-modal {
	top                       : 0;
	bottom                    : 0;
	right                     : 0;
	left                      : 0;
	position                  : absolute;
	// background-color: transparent;
	z-index                   : 20;
	border-radius             : 12px;
	backdrop-filter           : blur(250px);
	// background            : rgb(221, 221, 221);
	// background            : rgba(255, 255, 255, 0.4);
	// height: calc(100vh + 60px);
	// -webkit-backdrop-filter: blur(250px);
	// filter: blur(15px) ;
	// filter             : blur(0px);
	// -o-filter          : blur(0px);
	// -ms-filter         : blur(0px);
	// -moz-filter        : blur(0px);
	// -webkit-filter     : blur(0px);
}

.modal-component-container {
	width           : 428px;
	min-height      : 204px;
	border-radius   : 10px;
	background-color: $white;
	display         : flex;
	flex-direction  : column;
	box-shadow      : 2px 8px 15px rgba(0, 0, 0, 0.1);
}

.invite-modal {
	top                : 0;
	bottom             : 0;
	right              : 0;
	left               : 0;
	position           : absolute;
	// background-color: transparent;
	display            : flex;
	justify-content    : center;
	align-items        : center;
	z-index            : 20;
	// background: rgba(255, 255, 255, 0.1);
	// opacity: 0.75;

	background        : rgba(255, 255, 255, 0.7);
	// background: rgba(0, 0, 0, 0.2);
	// backdrop-filter: blur(5px);
}

.invite-modal-container {
	width           : 639px;
	min-height      : 367px;
	border-radius   : 12px;
	background-color: $white;
	box-shadow      : 2px 8px 15px rgba(0, 0, 0, 0.1);
	padding         : 0px 32px 30px 32px;
}

.question-feedback-container {
	min-width       : 723px;
	max-width       : 900px;
	min-height      : 500px;
	background-color: $white;
	border-radius   : 12px;
	box-shadow      : 2px 8px 15px rgba(0, 0, 0, 0.1);
	padding         : 0px 70px;
}

@media only screen and (max-width: 770px) {
	.invite-modal {
		display      : flex;
		border-radius: 12px;

		align-items: flex-start;
	}

	.question-feedback-container {
		max-width : 384px;
		min-width : 0px;
		height    : 100vh;
		max-height: auto;
		padding   : 0px 20px;
	}
}