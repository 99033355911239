@import "./../style-assets/colors";

.primary-checkbox {
    width  : 100%;
    display: flex;

    .primary-checkbox-container {
        display    : flex;
        align-items: center;

        input[type=checkbox].primary-checkbox-input {
            width             : 16px;
            height            : 16px;
            border-radius     : 6px;
            appearance        : none;
            -webkit-appearance: none;
            outline           : none;
            border            : 1px solid #000;
            cursor            : pointer;
        }

        input[type=checkbox].primary-checkbox-input:checked {
            background-color: #444;
            border-color    : #444;
            position        : relative;
        }


        input[type=checkbox].primary-checkbox-input:checked::after {
            content     : '';
            position    : absolute;
            width       : 4px;
            height      : 8px;
            border      : solid #fff;
            border-width: 0 2px 2px 0;
            transform   : rotate(45deg);
            top         : 2px;
            left        : 6px;
        }
    }
}

.primary-light-checkbox {
    width  : 100%;
    display: flex;

    .primary-light-checkbox-container {
        display      : flex;
        align-items  : center;
        border-radius: 24px !important;

        input[type=checkbox] {
            height       : 17px;
            width        : 17px;
            border-radius: 24px !important;
        }

        input[type=checkbox]:checked {
            accent-color : $dark-100;
            border-radius: 6px;
        }
    }
}

.checkbox-with-input-container {
    display      : flex;
    align-items  : center;
    border-radius: 24px !important;

    input[type=checkbox] {
        height       : 17px;
        width        : 17px;
        border-radius: 24px !important;
    }

    input[type=checkbox]:checked {
        accent-color : $dark-100;
        border-radius: 6px;
    }
}